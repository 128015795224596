import React from 'react';
import { ToggleSwitchProps } from 'wix-ui-tpa';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { Block } from './Block';
import { BlockAlign } from './BlockAlign';
import { Theme } from '../../context/theme';

interface SwitchBlockProps extends ToggleSwitchProps, Theme {
  children?: React.ReactNode;
}

export const SwitchBlock: React.FC<SwitchBlockProps> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Block
      end={<ToggleSwitch {...rest} />}
      align={BlockAlign.center}
      autoContent
      className={className}
    >
      {children}
    </Block>
  );
};

SwitchBlock.displayName = 'SwitchBlock';
