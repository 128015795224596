import React from 'react';
import { Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './GroupNotFoundComponent.st.css';
import { Container } from '../../../../common/components/Container/Container';
import {
  BlockAlign,
  BlockFlow,
  Block,
} from '../../../../common/components/Block';
import { Button } from '../../../../common/components/Button';

export interface GroupNotFoundProps {
  goToGroupList(): void;
  className?: string;
}

const GroupNotFoundComponent = (
  props: GroupNotFoundProps & WithTranslation,
) => {
  const { t, goToGroupList, ...rest } = props;
  return (
    <Container className={st(classes.root, {}, rest.className)}>
      <Block
        flow={BlockFlow.row}
        place={BlockAlign.center}
        align={BlockAlign.center}
        className={classes.groupNotFound}
      >
        <Text
          tagName="h2"
          typography={TextTypography.largeTitle}
          className={classes.title}
        >
          {t('groups-web.groupNotFound.title')}
        </Text>
        <Text>{t('groups-web.groupNotFound.caption')}</Text>
        <Block place={BlockAlign.center}>
          <Button
            priority={ButtonPriority.primary}
            className={classes.actionButton}
            onClick={() => goToGroupList()}
          >
            {t('groups-web.groupNotFound.action')}
          </Button>
        </Block>
      </Block>
    </Container>
  );
};

export const GroupNotFound = withTranslation()(GroupNotFoundComponent);
