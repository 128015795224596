import React, { FC } from 'react';
import { TextButtonPriority } from 'wix-ui-tpa';

import { LayoutProps } from './types';

import { classes, st } from '../CustomTab.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { Spinner } from '../../../../../common/components/Spinner/Spinner';

export const MobileLayout: FC<LayoutProps> = (props) => {
  const { isLoading, children, onCancel, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <div className={st(classes.root, { mobile: true })}>
      <div className={classes.header}>
        <TextButton onClick={onCancel} priority={TextButtonPriority.secondary}>
          {t('groups-web.custom-tab.cancel')}
        </TextButton>
        <TextButton
          onClick={onSubmit}
          priority={TextButtonPriority.primary}
          disabled={isLoading}
        >
          {t('groups-web.custom-tab.save')}
        </TextButton>
      </div>
      <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>
    </div>
  );
};
