import React, { FC } from 'react';

import { ButtonPriority } from 'wix-ui-tpa';

import { LayoutProps } from './types';

import { classes } from '../CustomTab.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../../common/components/Spinner/Spinner';
import { Button } from '../../../../../common/components/Button/Button';
import { Loader } from '../../../../../common/components/Loader/Loader';

export const DesktopLayout: FC<LayoutProps> = (props) => {
  const { isLoading, children, onCancel, onSubmit } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <React.Suspense fallback={<Spinner />}>
        {children}
        <div className={classes.actions}>
          <Button priority={ButtonPriority.basicSecondary} onClick={onCancel}>
            {t('groups-web.custom-tab.cancel')}
          </Button>
          <Button
            className={classes.submit}
            priority={ButtonPriority.basic}
            onClick={onSubmit}
            disabled={isLoading}
            prefixIcon={isLoading ? <Loader /> : undefined}
          >
            {t('groups-web.custom-tab.save')}
          </Button>
        </div>
      </React.Suspense>
    </div>
  );
};
