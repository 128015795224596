import React from 'react';
import { getUrlWithQuery } from '../../../../../common/utils/getUrlWithQuery';

interface NextPageSeoProps {
  url: string;
  query?: { cursor: string; prevCursor?: string };
}

export const PaginationSeo: React.FC<NextPageSeoProps> = (props) => {
  const { url, query } = props;
  if (!url || !query) {
    return null;
  }
  const { cursor, prevCursor } = query;
  return (
    <>
      {prevCursor ? (
        <link rel="prev" href={getUrlWithQuery(url, { cursor: prevCursor! })} />
      ) : null}
      {cursor ? (
        <link rel="next" href={getUrlWithQuery(url, { cursor })} />
      ) : null}
    </>
  );
};

PaginationSeo.displayName = 'PaginationSeo';
