import React from 'react';
import { ButtonPriority, ButtonSize } from 'wix-ui-tpa';
import { TFunction } from '@wix/yoshi-flow-editor';

import { Block } from '../../../../common/components/Block';
import { Button } from '../../../../common/components/Button';
import { classes } from './PendingMembers.st.css';
import { IPM } from './usePendingMembers';

export const PendingActions: React.FC<
  { t: TFunction; memberId: string; disabled: boolean } & Pick<
    IPM,
    'approvePendingMember' | 'declinePendingMember'
  >
> = (props) => {
  const {
    approvePendingMember,
    declinePendingMember,
    t,
    memberId,
    disabled,
  } = props;
  return (
    <Block autoContent={true}>
      <Button
        onClick={() =>
          declinePendingMember({ siteMemberId: memberId /* TODO: reason*/ })
        }
        priority={ButtonPriority.secondary}
        size={ButtonSize.tiny}
        className={classes.actionButton}
        fullWidth
        disabled={disabled}
      >
        {t('groups-web.members.pending.decline')}
      </Button>
      <Button
        onClick={() => approvePendingMember(memberId)}
        priority={ButtonPriority.primary}
        size={ButtonSize.tiny}
        className={classes.actionButton}
        fullWidth
        disabled={disabled}
      >
        {t('groups-web.members.pending.approve')}
      </Button>
    </Block>
  );
};
