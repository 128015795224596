import React from 'react';
import { TextButton as TPATextButton, TextButtonProps } from 'wix-ui-tpa';

import { st, classes } from './TextButton.st.css';
import { Theme } from '../../context/theme';

export const TextButton: React.FC<TextButtonProps & Theme> = (props) => (
  <TPATextButton
    {...props}
    className={st(
      classes.root,
      { bw: !!props.forceBlackAndWhite },
      props.className,
    )}
  />
);
TextButton.displayName = 'TextButton';
