import { useGroupV2 } from '../../contexts/Group/useGroupV2';
import { isAdminV2, RequestState } from '@wix/social-groups-api';
import {
  IJoinGroupRequests,
  JoinGroupRequestType,
} from '../../controllers/joinGroupRequests/IJoinGroupRequests';

import { useGroupBI } from '../hooks/useGroupBI';
import { Rejection } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { GroupPendingMember } from '../../../../common/api/model/GroupMember/GroupPendingMember';
import { useJoinGroupRequests } from '../../contexts/JoinGroupRequests/useJoinGroupRequests';

function isLoading(
  joinGroupRequest: IJoinGroupRequests['joinGroupRequestRequests'] | undefined,
) {
  return (
    !!joinGroupRequest &&
    joinGroupRequest.type === JoinGroupRequestType.queryPendingJoinRequests &&
    joinGroupRequest.status === RequestState.PENDING
  );
}

export interface IPM {
  pendingMembers: GroupPendingMember[] | null;
  loading: undefined | boolean;
  total: any;
  showMore: boolean;
  canSeePendingMembers: boolean;
  isMemberUpdating(memberId: string): boolean;
  loadPendingMembers(): void;
  declinePendingMember(rejection: Rejection): void;
  approvePendingMember(memberId: string): void;
}

export const usePendingMembers = (): IPM => {
  const { membership, groupV2 } = useGroupV2();
  const {
    joinGroupRequestsResponse,
    joinGroupRequestsActions,
    joinGroupRequestRequests,
  } = useJoinGroupRequests();
  const bi = useGroupBI();

  const loading = isLoading(joinGroupRequestRequests!);
  const canSeePendingMembers = isAdminV2(membership!);
  const total = joinGroupRequestsResponse?.metadata
    ? joinGroupRequestsResponse?.metadata.total
    : groupV2?.pendingMembersCount;

  const pendingRequests: Set<string> = new Set<string>(
    joinGroupRequestRequests?.siteMemberIds,
  );

  const pendingMembers = joinGroupRequestsResponse?.pendingMembers
    ? Object.values(joinGroupRequestsResponse?.pendingMembers).map(
        (m) => new GroupPendingMember(m),
      )
    : [];

  const showMore = pendingMembers.length < (total || 0);

  function loadPendingMembers() {
    const admin = isAdminV2(membership!);
    if (total && admin) {
      joinGroupRequestsActions?.queryPendingJoinRequests();
    }
  }

  const approvePendingMember = (memberId: string) => {
    try {
      joinGroupRequestsActions?.approveJoinGroupRequests({
        siteMemberIds: [memberId],
      });
      bi.groupsMemberRequestApproveDecline({ type: 'approve', memberId });
    } catch (e) {
      // TODO: handle with UI
      console.error('Approve Pending Members: FAIL');
    }
  };

  const declinePendingMember = (rejection: Rejection) => {
    try {
      joinGroupRequestsActions?.rejectJoinGroupRequests({
        rejections: [rejection],
      });
      bi.groupsMemberRequestApproveDecline({
        type: 'decline',
        memberId: rejection.siteMemberId,
      });
    } catch (e) {
      console.error('Reject Pending Members: FAIL');
    }
  };

  function isMemberUpdating(memberId: string) {
    return pendingRequests.has(memberId);
  }

  return {
    pendingMembers,
    loading,
    total,
    showMore,
    canSeePendingMembers,
    isMemberUpdating,
    loadPendingMembers,
    declinePendingMember,
    approvePendingMember,
  };
};
