import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TextButtonPriority } from 'wix-ui-tpa';
import { TextButton } from 'common/components/TextButton/TextButton';
import { classes, st } from './SeeMore.st.css';

export interface SeeMoreProps {
  onClick(): void;
  shouldTruncateContent: boolean;
}
export const SeeMore: React.FC<SeeMoreProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.root)}>
      <TextButton
        className={st(classes.seeMore)}
        priority={TextButtonPriority.primary}
        onClick={props.onClick}
      >
        {props.shouldTruncateContent
          ? t('groups-web.discussion.feed.see-more')
          : t('groups-web.discussion.feed.see-less')}
      </TextButton>
    </div>
  );
};
