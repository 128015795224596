import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { Container } from '../Container/Container';
import { Modal, ModalProps } from '../Modal';
import { classes, st } from './ShareModal.st.css';
import { ShareComponent } from './ShareComponent';
import { Theme, withTheme } from '../../context/theme';

interface ShareModalProps extends ModalProps {
  shareUrl: string;
  title: string;
  onShare?(): void;
}

const ShareModalComponent: React.FC<ShareModalProps & Theme> = (props) => {
  const {
    isOpen,
    onRequestClose,
    title,
    shareUrl,
    onShare,
    forceBlackAndWhite,
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container className={st(classes.root, { bw: !!forceBlackAndWhite })}>
        <Text
          typography={TextTypography.largeTitle}
          tagName="h2"
          className={classes.title}
        >
          {title}
        </Text>
        <ShareComponent onShare={onShare} shareUrl={shareUrl} />
      </Container>
    </Modal>
  );
};
export const ShareModal = withTheme(
  ShareModalComponent,
) as React.ComponentType<ShareModalProps>;
ShareModal.displayName = 'ShareModal';
