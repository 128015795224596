import React, { useEffect, useRef, useState } from 'react';
import { NewPost } from './NewPost';
import { NewPostModal } from '../../Modals/NewPostModal/NewPostModal';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { ActionType } from '../../UserStatus/ActionType';
import { useUser } from '../../../../../common/context/user/useUser';
import { memberWrapper } from '../../../../../common/api/model/Member';
import { removeCreateNewPostDeepLink } from './index';
import { FeedControllerProps } from '../../../types/FeedControllerProps';
import { RequestState } from '@wix/social-groups-api';
import { useUserActions } from '../../UserStatus/useUserActions';
import { FeedRequestState } from '../../../types/FeedRequestState';
import { BUTTON_TYPES } from '../../../../../common/ContentEditor/consts';

interface Props extends Pick<FeedControllerProps, 'feedRequest'> {
  onCreatePost(content: any, topicId?: string): void;

  topicId?: string;
}

function isPostCreating(feedRequest?: FeedRequestState) {
  return feedRequest?.createPost === RequestState.PENDING;
}

export const WritePost: React.FC<Props> = (props) => {
  const [draft, setDraft] = useState<RawDraftContentState<any> | null>(null);
  const { initAction, currentAction, finishAction } = useUserActions();
  const isOpen = currentAction === ActionType.WRITE_POST;
  const { myMember } = useUser();
  const { photo } = memberWrapper(myMember!).profile!;
  const initialIntent = useRef<any>(null);
  const editorNodeRef = useRef<any>(null);

  useEffect(() => {
    if (props.feedRequest && isOpen && !isPostCreating(props.feedRequest)) {
      closeNewPost();
    }
  }, [JSON.stringify(props.feedRequest)]);

  const onEditorNodeChanged = (editorNode: any) => {
    editorNodeRef.current = editorNode;
    if (editorNode && initialIntent.current) {
      initialIntent.current.resolve();
    }
  };

  const closeNewPost = () => {
    finishAction();
  };

  // event and initialIntentType exists only in case of click on plugin icons.
  const maybeWritePost = async (event?: any, initialIntentType?: string) => {
    await initAction(ActionType.WRITE_POST, 'top_rce_area');
    if (!initialIntentType) {
      return;
    }

    if (editorNodeRef.current) {
      // RCE is ready
      handleInitialToolbarButtonAction(event, initialIntentType!);
    } else {
      // need to wait for render RCE (as we wrapped it with React.Suspense)
      return new Promise(async (resolve) => {
        initialIntent.current = { resolve };
      }).then(() => {
        handleInitialToolbarButtonAction(event, initialIntentType!);
        initialIntent.current = null;
      });
    }
  };

  const handleInitialToolbarButtonAction = (
    event: any,
    initialIntentType: string,
  ) => {
    if (!editorNodeRef.current) {
      return;
    }

    const { buttons } = editorNodeRef.current.getToolbarProps();
    const button = buttons[initialIntentType!];
    if (!button) {
      return;
    }

    if (button.type === BUTTON_TYPES.BUTTON) {
      button.onClick(event);
    }
    if (button.type === BUTTON_TYPES.FILE) {
      button.onChange(event);
    }
  };

  const handleDraftSave = (newDraft: any) => {
    setDraft(newDraft);
    removeCreateNewPostDeepLink();
  };

  const handleCreatePost = (content: any, topicId?: string) => {
    props.onCreatePost(content, topicId);
    setDraft(null);
    removeCreateNewPostDeepLink();
  };

  return (
    <>
      <NewPost
        onClick={maybeWritePost}
        profilePhoto={photo?.url!}
        draft={draft!}
      />
      <NewPostModal
        onEditorNodeChanged={onEditorNodeChanged}
        initialContentState={draft!}
        isPostPublishing={isPostCreating(props.feedRequest)}
        isOpen={isOpen}
        onSubmit={handleCreatePost}
        onSaveDraft={handleDraftSave}
        topicId={props.topicId}
        discardPost={closeNewPost}
      />
    </>
  );
};

WritePost.displayName = 'WritePost';
