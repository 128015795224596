import React, { useMemo } from 'react';

import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EventsErrorBoundary } from './EventsErrorBoundary';

import { Spinner } from '../../../../common/components/Spinner';
import { EventsContext } from '../../contexts/events/EventsContext';
import { EmptyState } from '../EmptyState/EmptyState';

import { Event } from '../../../../common/components/Event/Event';
import {
  sortPast,
  filterEvents,
} from '../../../../common/components/Event/helpers';

import { classes } from './Events.st.css';

interface EventsProps {
  groupId: string;
}

type Props = EventsProps;
const EventsComponent: React.FC<Props> = ({ groupId }) => {
  const { t } = useTranslation();
  const { events, fetch, error } = React.useContext(EventsContext);

  React.useEffect(() => {
    fetch();
  }, []);

  const [upcoming, ended] = useMemo(() => {
    if (!events) {
      return [];
    }

    const [scheduled, past] = filterEvents(events);

    return [scheduled, sortPast(past)];
  }, [events]);

  if (error || (events && !events.length)) {
    return (
      <EmptyState
        title={t('groups-web.events.empty.title')}
        content={t('groups-web.events.empty.subtitle')}
      />
    );
  }

  if (!events) {
    return <Spinner offset="M" label={t('groups-web.loading')} />;
  }

  return (
    <div className={classes.root}>
      {upcoming?.length ? (
        <>
          <Text tagName="h2" className={classes.title}>
            {t('groups-web.events.upcoming')}
          </Text>
          {upcoming.map((event, i) => (
            <Event
              key={event.id}
              event={event}
              last={i === upcoming.length - 1}
              groupId={groupId}
            />
          ))}
        </>
      ) : null}
      {ended?.length ? (
        <>
          <Text tagName="h2" className={classes.title}>
            {t('groups-web.events.ended')}
          </Text>
          {ended.map((event, i) => (
            <Event
              key={event.id}
              event={event}
              last={i === ended.length - 1}
              groupId={groupId}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

export const Events = EventsErrorBoundary(
  EventsComponent,
) as React.ComponentType<EventsProps>;
