import React from 'react';
import { Text } from 'wix-ui-tpa';
import { FeedItem, FeedItemProps } from '../FeedItem';
import { st, classes } from './PinnedPost.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Box } from '../../../../../common/components/Box/Box';
import { PinIcon } from '../../icons/PinIcon';

interface PinnedPostProps extends FeedItemProps {}

const PinnedPostComponent: React.FC<PinnedPostProps & WithTranslation> = ({
  t,
  ...feedItemProps
}) => {
  return (
    <div className={st(classes.root, {})}>
      <Box className={classes.header}>
        <PinIcon className={classes.icon} width={18} height={17} />
        <Text className={classes.headerText}>
          {t('groups-web.discussion.feed.pined-post')}
        </Text>
      </Box>
      <FeedItem {...feedItemProps} />
    </div>
  );
};

export const PinnedPost = withTranslation()(
  PinnedPostComponent,
) as React.ComponentType<PinnedPostProps>;
