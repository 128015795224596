import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import { TFunction } from '@wix/yoshi-flow-editor';

import { classes } from './PendingMembers.st.css';

export const Title: React.FC<{ total: number; t: TFunction }> = ({
  total,
  t,
}) => {
  const title = `${t('groups-web.members.pending.requests')}`;
  const number = total < 10 ? total : '9+';
  return (
    <div className={classes.titleWrapper}>
      <Text typography={TextTypography.runningText} className={classes.title}>
        {title}
      </Text>
      <span className={classes.badge}>{number}</span>
    </div>
  );
};
