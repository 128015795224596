import React from 'react';

import { GroupRule } from '@wix/social-groups-api';

import { Text, TextTypography } from 'wix-ui-tpa';

import { classes } from './GroupRules.st.css';
import * as aboutStyles from '../About.st.css';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../../contexts/GroupActions/WithGroupActions';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../../common/components/Spinner/Spinner';
import { Box } from '../../../../../common/components/Box/Box';
import { compose } from '../../../../../common/utils/compose';
import { Header } from '../../../../../common/components/Text/Header';

interface GroupRulesProps {
  rules: GroupRule[];
}

class GroupRulesComponent extends React.Component<
  GroupRulesProps & WithTranslation & WithGroupActionProps
> {
  componentDidMount() {
    this.props.fetchGroupRules();
  }

  render() {
    const { t, rules } = this.props;

    if (!rules) {
      return <Spinner offset="M" label={t('groups-web.loading')} />;
    }

    if (!rules.length) {
      return null;
    }

    return (
      <Box className={classes.root}>
        <div className={classes.cardHeader}>
          <Header className={aboutStyles.classes.header}>
            {t('groups-web.about.rules.title')}
          </Header>
        </div>
        <ul>
          {rules.map((rule) => (
            <li key={rule.id} className={classes.rule}>
              <Text
                tagName="p"
                typography={TextTypography.largeTitle}
                className={classes.title}
              >
                {rule.title}
              </Text>
              <Text
                tagName="p"
                typography={TextTypography.listText}
                className={classes.description}
              >
                {rule.description}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
    );
  }
}

export const GroupRules = compose(
  withTranslation(),
  WithGroupActions,
)(GroupRulesComponent);
